// var lastId,
//     topMenu = $(".header__menu"),
//     topMenuHeight = topMenu.outerHeight(),
// menuItems = topMenu.find("a"),
// scrollItems = menuItems.map(function(){
// 	var item = $($(this).attr("href"));
// 	if (item.length) { return item; }
// });
// menuItems.click(function(e){
//   $('.header__menu').removeClass('menu-visible');
//   $('.header .trigger').removeClass('menu-visible');
//   var href = $(this).attr("href"),
//       offsetTop = href === "#" ? 0 : $(href).offset().top;
//   $('html, body').stop().animate({
//     scrollTop: offsetTop
//   }, 600);
//   e.preventDefault();
// });
// $(window).scroll(function(){
//   var fromTop = $(this).scrollTop()+topMenuHeight;
//   var cur = scrollItems.map(function(){
//     if ($(this).offset().top < fromTop)
//       return this;
//   });
//   cur = cur[cur.length-1];
//   var id = cur && cur.length ? cur[0].id : "";
//   if (lastId !== id) {
//     lastId = id;
//     menuItems
//       .parent().removeClass("current")
//       .end().filter("[href='#"+id+"']").parent().addClass("current");
//   }
// });

$(document).ready(function () {

	initLanguage();
	initMatchHeight();
	initStickyHeader();
	initHomeSlider();
	initFeaturesDesc();
	initMobileMenu();
	initPlayerReady();
	initFixSafari();

});

function initLanguage() {

	$('.header .language .language__current').on('click',function() {
		$('.header .language').stop(true,true).toggleClass('active');
	});
	$(document).on("click", function(evt) {
		if (!evt.target.closest('.language') && !evt.target.classList.contains('language')) {
			$(".header .language").removeClass('active');
		}
	});

}

function initMatchHeight() {

  $('.height').matchHeight();

}

function initStickyHeader() {

  var menu = document.querySelector('.header');
	var origOffsetY = menu.offsetTop;
	function scroll () {
		if ($(window).scrollTop() > origOffsetY) {
			$('.header').addClass('scrolling');
		} else {
			$('.header').removeClass('scrolling');
		}
	}
	scroll();
	document.onscroll = scroll;

}

function initHomeSlider() {

	$('.sliders__slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		slide: 'div.item',
		asNavFor: '.sliders__carousel',
		fade: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 6000
	});
	$('.sliders__carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		slide: 'div.item',
		asNavFor: '.sliders__slider',
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 6000
	});

}

function initFeaturesDesc() {
  $('.features__item')
	.mouseover(function() {
    $(this).find('.features__inner p').stop(true,true).slideToggle().promise().done();
  })
  .mouseout(function() {
    $(this).find('.features__inner p').stop(true,true).slideToggle().promise().done();
  });
}

function initMobileMenu() {
	$('.header .trigger').on('click',function() {
		$(this).stop(true,true).toggleClass('menu-visible');
    $('.header__menu').stop(true,true).toggleClass('menu-visible');
	});
}

function initPlayerReady() {
	$('.video__player').on('click',function(e) {
		$(this).fadeOut(400);
		$(".video__item .video__wrapper iframe")[0].src += "?autoplay=1&rel=0";
		$(".video__copy").addClass('smaller');
		e.preventDefault();
	});
}

function initFixSafari() {
	if (navigator.userAgent.indexOf('Safari') != -1) {
    $('.sliders__inner').addClass('safari-fix');
  }
}

$(function() {
	function onScrollInit( items, trigger ) {
		items.each( function() {
		var osElement = $(this),
			osAnimationClass = osElement.attr('data-os-animation'),
			osAnimationDelay = osElement.attr('data-os-animation-delay');
			osElement.css({
				'-webkit-animation-delay': osAnimationDelay,
				'-moz-animation-delay': osAnimationDelay,
				'animation-delay': osAnimationDelay
			});
			var osTrigger = ( trigger ) ? trigger : osElement;
			osTrigger.waypoint(function() {
				osElement.addClass('animated').addClass(osAnimationClass);
				},{
					triggerOnce: true,
					offset: '110%'
			});
		});
	}
	onScrollInit( $('.os-animation') );
	onScrollInit( $('.staggered-animation'), $('.staggered-animation-container') );
});
